.tableOfContents {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 75vh;
  margin: 0 auto
}

.myNameContainer {
  display: inline-block;
  width: 100vw;
  height: 15vh;
}

.myName {
  font-family: "Montserrat Underline", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
  font-size: 14vh;
  font-style: italic;
  margin: 0 auto
}


.workTitles {
  
  font-family: "Michroma", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  height: 11vh;
  width: 100vw;
  padding-top: 1vh;
}

.workTitle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30vw;
  font-size: 2.5vh;
  text-align: center;
  margin: auto;
}

.workTitle.w0 {
  /* animation: flicker .5s linear 8, flicker 2s linear 2; */
}

.workTitle.w1 {
  /* animation: flicker .25s linear 8, flicker 1s linear 3; */
}

.workTitle.w2 {
  /* animation: flicker 1s linear 2, flicker 3s linear 2; */
}

.chapterList {

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.chapters {
  display: flex;
  justify-content: center;
  font-size: 2vw;
  font-weight: bold;
  font-family: "Michroma", sans-serif;
  line-height: 1;
  cursor: pointer;
  margin: 1vh;
  width: auto
}

/* Media Queries */
@media (max-width: 1024px) {

  /* Tablet breakpoint */
  .myName {
    font-size: 11vh;
  }


  .chapters {
    font-size: 4.5vw;
    line-height: 1;

  }

}

@media (max-width: 768px) {

  /* Mobile breakpoint */


  .myName {
    font-size: 14vw
  }

  .workTitle {
    font-size: 2vh;
    justify-content: center;
    flex-direction: column;
  }

  .chapters {
    font-size: 4.5vw;
    line-height: 1;
    text-shadow: 0 0 2px #fff, 0 0 .05em #fff, 0 0 .1em #fff, 0 0 .2em #0fa;


  }

  .workTitles {
    flex-direction: column;
    height: auto;
  }

  .myNameContainer {
    height: auto;
  }

}