/* General styles */
.myHobbies {
  text-indent: 5em;
  text-align: left;
  line-height: 1.5;
  display: flex;
  justify-content: center;
  margin: .2em 2em 1.5em;
}

.helloWorld {
  font-family: "Montserrat Underline", sans-serif;
  display: flex;
  justify-content: center;
  text-decoration: underline;
}

.hobbies {
  max-width: 90%;
  display: flex;
  flex-direction: column;
  z-index: 2;
  background-color: #272d34;
  border: 0.6rem solid #fff;
  border-radius: 2rem;
  margin: 0.4em;
  box-shadow: 0 0 7px #fff, 0 0 10px #fff, 0 0 21px #fff, 0 0 42px #0fa, 0 0 82px #0fa, 0 0 92px #0fa, inset 0 0 1.3rem #0fa;
}


.contactIcons {
  filter: drop-shadow(2px 0px 4px #fff) drop-shadow(2px 0px 3px #0fa);
  justify-content: center;
  height: 6vh;
  width: 6vh;
  
}






.imageCover {
  width: 25vh;
  /* height: 25vh; */
  border-radius: 5%;
  object-fit: cover;
  margin: auto;
  padding: auto;
  filter: drop-shadow(2px 0px 4px #fff) drop-shadow(2px 0px 3px #0fa);

}

.topicContainer {
  margin: 2%;
  height: 90%;
  width: 95%;
  overflow-y: scroll;
  border-style: groove;
  border-radius: 5%;
  box-shadow: 1px 0px 4px #FFF, 2px 0px 4px #FFF, 3px 0px 4px #FFF, 2px 0px 3px #0fa, 2px 3px 15px #0fa, 20px 0vw 200px #0fa;
    text-shadow:
    0 0 .1em #fff,
    -3em .75em 1em #0fa;
}



.topicDiv {
  margin: 1% 3%;
  text-decoration: none;
  color: #fff;
}

.contactPage{
  display: flex;
  flex-direction: row; 
}

.formPage {
  width: 50%;
  margin: auto;
}

.submitFormButton{
  cursor: pointer;
  background-color: #0fa;
}

.closeTopicButton{
  color:#0fa
}

a {
  text-decoration: none;
  color: #fff;
}

.topicTitle {
  text-align: right;
  /* font-family: Bungee_Shade; */
  font-family: "Montserrat Underline", sans-serif;
  color: #000;
  font-size: 5vh;
  text-shadow: 1px 0px 4px #FFF, 2px 0px 4px #FFF, 3px 0px 4px #FFF, 2px 0px 3px #0fa, 2px 3px 15px #0fa, 20px 0vw 20px #0fa, -2em 0.25em 1em #0fa;
}

.indented-paragraph {
  font-family: Poppins-MediumItalic;
  text-indent: 5%;
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.paragraph-sections{
  font-family: "Montserrat Underline", sans-serif;
  color: #000;
  font-size: 2em;
  text-shadow: 1px 0px 4px #FFF, 2px 0px 4px #FFF, 3px 0px 4px #FFF, 2px 0px 3px #0fa, 2px 3px 15px #0fa, 20px 0vw 20px #0fa, -2em 0.25em 1em #0fa;
}

.paragraph-subsections{
  padding-top: 0;
  font-family: Poppins-MediumItalic;
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.responsibility {
  font-family: Poppins-SemiBoldItalic;

}

.linkedText {
  font-family: 'Poppins-SemiBoldItalic';
  justify-items: auto;
  
}

.popup{
  display: flex;
  flex-direction: column;
  /* justify-content:space-around; */
  border-style: groove;
  border-radius: 5%;
  height: 50vh;
  width: 50vw;
  background-color: #000;
  color: #0fa;
  box-shadow: 1px 0px 4px #FFF, 2px 0px 4px #FFF, 3px 0px 4px #FFF, 2px 0px 3px #0fa, 2px 3px 15px #0fa, 20px 0vw 200px #0fa;
  animation: 1s fadeIn forwards;
}

.closeModal {
  outline: none;
  border: none;
  border-radius: 40%;
  text-decoration: none;
  text-transform: uppercase;
  background: #202020;
  color: #eaeaea;
  padding: 10px 40px;
  position: absolute;
  bottom: 1%;
  right: 2%;
  box-shadow: 1px 0px 4px #FFF, 2px 0px 4px #FFF, 3px 0px 4px #FFF, 2px 0px 3px #0fa, 2px 3px 15px #0fa, inset 0 0 .25em #fff, inset 0 0 .51em #fff, inset 0 0 .75em #0fa;
}

input{
  width: 75%;
  word-wrap: break-word;
  text-wrap: wrap;
  box-sizing: border-box;
  text-align: justify;
}

#messageInput {
  min-height: 10vh;
  

  /* Adjust height as needed, potentially remove it */
  width: 100%;
  box-sizing: border-box;
  padding: 15px; /* Adjust padding as needed */
  word-wrap: break-word;
  line-height: 1.5;
}

input[type=text]:focus {
  background-color: lightblue;
}