/* General styles */
.div-with-bars {
    display: flex;
    /* justify-content:; */
    /* width: 100%; */
    /* margin-left: 2%;
    margin-right: 2%; */
    margin: 0 5%;
    height: 1.5vh;
    /* background-position: center; */
    box-shadow: inset 0px 4px #FFF, 2px 0px 4px #FFF, 3px 0px 4px #FFF, 2px 0px 3px #0fa, 2px 3px 15px #0fa, 20px 0vw 200px #0fa, inset 0 0 .25em #fff, inset 0 0 .51em #fff, inset 0 0 .75em #0fa;
}

.head {
   align-content: center;
    background: linear-gradient(to left, #272d34, #333 50%, #0fa 75%, #272d34);
    animation: flicker .5s linear 8;
}

.foot {

    background: linear-gradient(to right, #272d34, #333 50%, #0fa 75%, #272d34);
    animation: flicker .3s linear 15;
}

footer {
    height: 11vh;
    
}

header {
    height: 4vh
}


/* Social media icons */
.social-media-icons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    color: #0fa;
    /* margin: 3% 5% 5vh 5%; */
    height: 10vh;
    /* margin-bottom: 20vh; */
}

.iconLinks {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20vh;
    margin: 0;
    /* filter: grayscale(.5) */
    filter: drop-shadow(2px 0px 4px #fff) drop-shadow(2px 0px 3px #0fa) ;
    /* margin: 3% 10% 3vh 10%; */
    
}




img.linkedIcons.hovered {
    /* filter: invert(100%); */
    filter: invert(100%) drop-shadow(0 0 4px #fff) drop-shadow(0 0 4px #FFF) drop-shadow(0 0 3px #0fa) drop-shadow(0px 0px 15px #0fa) drop-shadow(0px 0vw 20px #0fa);


}

img.linkedIcons.unhovered {


    animation: fadeOutIcon 1s;
}

.linkedIcons {
    justify-content: center;
    height: 6vh;
    width: 6vh;

}

.iconLinks.gmail, .iconLinks.phone {
    display: none;
} 



/* Animations */

@keyframes fadeOutIcon {
    from {


        filter: invert(100%) drop-shadow(0 04px #fff) drop-shadow(0 0 4px #FFF) drop-shadow(0 0 3px #0fa) drop-shadow(0px 0px 15px #0fa) drop-shadow(0px 0vw 20px #0fa);
    }

    to {

        filter: invert(0), drop-shadow(none);
    }
}

@keyframes flicker {

    0%,
    19.999%,
    22%,
    62.999%,
    64%,
    64.999%,
    70%,
    100% {
        opacity: .99;
    }

    20%,
    21.999%,
    63%,
    63.999%,
    65%,
    69.999% {
        opacity: 0.4;
    }
}

/* Media Queries for Responsiveness */

@media (max-width: 1024px) {
    /* Adjust styles for tablets */

     .iconLinks.gmail {
        display: flex; 
    } 


}

@media (max-width: 480px) {
    /* Adjust styles for phones */

    .iconLinks.phone{
        display: flex; 
    } 


}