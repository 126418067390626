.App {
  display: flex;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  animation: fadeIn 2.5s forwards;
  flex-direction: column;
  overflow: hidden;
  position: absolute;

}



html,
body {
  background-color: #272d34;


  /* opacity: .9; */
}

.closeTopicButton {
  outline: none;
  border: none;
  border-radius: 40%;
  text-decoration: none;
  text-transform: uppercase;
  background: #202020;
  /* color: #eaeaea; */
  padding: 10px 40px;
  position: absolute;
  bottom: 1%;
  right: 2%;
  box-shadow: 1px 0px 4px #FFF, 2px 0px 4px #FFF, 3px 0px 4px #FFF, 2px 0px 3px #0fa, 2px 3px 15px #0fa, inset 0 0 .25em #fff, inset 0 0 .51em #fff, inset 0 0 .75em #0fa;
}

.topicSelectedDiv {
  position: absolute;
  /* color: #fff; */
  border: #fff;
  border-style: groove;
  margin: 5%;
  background-color: black;
  border-radius: 5%;
  z-index: 50;
  height: 85%;
  width: 75%;
  box-shadow: 1px 0px 4px #FFF, 2px 0px 4px #FFF, 3px 0px 4px #FFF, 2px 0px 3px #0fa, 2px 3px 15px #0fa, 20px 0vw 200px #0fa, inset 0 0 1em #fff, inset 0 0 2em #fff, inset 0 0 3em #0fa;
  transition: all .75s ease-in-out;
  overflow: hidden;
}

.topicSelectedDiv.fadeIn {
  animation: fadeIn 1.5s forwards;
}

.topicSelectedDiv.fadeOut {
  animation: fadeOut .5s forwards;
}



/* @font-face {
  font-family: 'YourFontName';
  src: url('./fonts/YourFontName.ttf') format('truetype');
} */

:root {
  font-family: InterVariable, sans-serif;
  font-feature-settings: 'liga' 1, 'calt' 1;
}

@supports (font-variation-settings: normal) {
  :root {
    font-family: InterVariable, sans-serif;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.transition {
  height: 80%;
  width: 50%;
  /* transition: all .75s ease-in-out allow-discrete; */
}

.anim-trans {
  animation: anim 1s linear;
}

@keyframes anim {
  from {
    position: absolute;
    transform: translateY(110vh) translateX(110vh);
  }
}

/* Improved media queries */
@media (max-width: 1024px) {

  /* Tablet breakpoint */
  .topicSelectedDiv {
    width: 90%;
    height: 90%;
    margin: 0;
  }
}

@media (max-width: 768px) {

  /* Phone breakpoint */
  .topicSelectedDiv {
    width: 100%;
    height: 90%;
    margin: 0;
  }

  /* Additional adjustments for smaller screens */
}



.pink {
  &.App {
    /* animation: fadeIn 1s forwards; */
    color: darkgoldenrod;
    background-color: hotpink;
    
  }

  .closeTopicButton {
    background: purple;
      }


}